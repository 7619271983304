import { createStore } from 'vuex'
import { content } from './content'
import {markRaw} from "vue";
import axios from "axios";

export default createStore({
  state: {
    page: 'homePage',
    language: 'ro',
    fetching: false,
    names: undefined
  },
  getters: {
    sectionContent: (state) => (section) => content[state.page][state.language][section],
  },
  mutations: {
    SET_STATE (state, {name, data}) {
      state[name] = data;
    },
    SET_LANGUAGE (state, language) {
      state.language = language
    }
  },
  actions: {
    async generate_name ({ commit }, query) {
      try {
        commit('SET_STATE', { name: 'fetching', data: true });
        const url = `https://riverside.testenv.ro/api/podcast-name-generator/generate`;
        const { data } = await axios.post(url, query);
        commit('SET_STATE', { name: 'names', data });
        commit('SET_STATE', { name: 'fetching', data: false });
      } catch (err) {

      }
    }
  },
  modules: {
  }
})
