<template>
  <h1>ABOUT</h1>
</template>

<style>
.a {
  @media only screen and (min-width: 0) {

  }
}
</style>