<template>
  <div class="names-view text-center">
    <h1>
      Generate names
    </h1>

    <form @submit.prevent="handleSubmit">
      <label class="block"
             for="">
        <input type="text"
               v-model="query"
               placeholder="add name here">
      </label>

      <p v-if="fetching">
        ... generating
      </p>

      <button v-else>
        Submit
      </button>
    </form>

    <p>
      {{ names }}
    </p>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed, ref} from "vue";

  const { state, dispatch, commit } = useStore();
  const query = ref('');
  const names = computed(() => state.names);
  const fetching = computed(() => state.fetching);

  function handleSubmit () {
    dispatch('generate_name', {query: query.value});
  }
</script>

<style lang="scss">
  .names-view {
    @media only screen and (min-width: 0) {

    }
  }
</style>